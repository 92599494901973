import React from "react"
import { Link, useStaticQuery } from "gatsby"
import { Seo } from "../../components/seo"
//import useLocation from "../hooks/useLocation"
import { ContactSectionEn } from "../../components/en/ContactSectionEn"
import { ServiceSectionEn } from "../../components/en/ServicesSectionEn"
import JobFormEn from "../../components/forms/JobFormEn"

const IndexEnPage = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiJobs(
        sort: { order: DESC, fields: DatePublication }
        limit: 8
        filter: { Archived: { eq: false }, Language: { eq: "Japanese" } }
      ) {
        nodes {
          JobDescription
          JobCategorie
          Allowances
          HolidayVacation
          CompanyProfile
          Insurance
          Manualid
          Salary
          SalaryIncrease
          Title
          WorkingTime
          Archived
          DatePublication(formatString: "YYYY年 MM月 DD日")
          locations {
            name
          }
          marks {
            name
          }
          types {
            name
            image {
              publicURL
            }
          }
        }
      }
      allStrapiTitles(
        limit: 5
        sort: { order: DESC, fields: DatePublication }
      ) {
        nodes {
          strapiId
          Content
          Title
          Description
          Slug
          Image {
            publicURL
          }
          LastUpdate
          DatePublication
          article_categories {
            Name
            Slug
          }
        }
      }

      file(relativePath: { eq: "03_slide_0222.webp" }) {
        publicURL
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const imageLog = query.file.publicURL

  //IMG
  const mobile_banner = require("../../images/home/mobile-hero.webp")
  //Gif
  const mainVideoBanner = require("../../images/home/919m-hero.mp4")

  // ENGLISH SECTION
  // Header Images
  const headerImg = [
    {
      img: require("../../images/home/en/header1.webp"),
    },
    {
      img: require("../../images/home/en/header2.webp"),
    },
    {
      img: require("../../images/home/en/header3.webp"),
    },
    {
      img: require("../../images/home/en/header4.webp"),
    },
  ]
  const ourSolution = [
    {
      img: require("../../images/home/en/benefits.webp"),
    },
    {
      img: require("../../images/home/en/opportunities.webp"),
    },
    {
      img: require("../../images/home/en/interview.webp"),
    },
  ]
  const howWorks = [
    {
      number: "1",
      name: "Inquiry",
    },
    {
      number: "2",
      name: "Counseling",
    },
    {
      number: "3",
      name: "Job Matching",
    },
    {
      number: "4",
      name: "Selection & Interview",
    },
    {
      number: "5",
      name: "Job Offer & Employment",
    },
  ]
  const makeHappy = require("../../images/home/en/makeHappy.png")

  return (
    <div className="english-page-body">
      <Seo
        title={`Mexico job offers, career change and employment information [QUICK GLOBAL MEXICO】`}
        image={imageLog}
        language={"en"}
      />
      <div className="banner-video">
        <video width="600" autoPlay muted loop className="no-show-mobile">
          <source src={mainVideoBanner} type="video/webm" />
        </video>
        <img
          src={mobile_banner}
          alt="mobile-banner"
          className="show-mobile mob-banner-en"
        />
        <h1 aria-label="Career in Mexico!" className="title-banner">
          Career in Mexico!
        </h1>
        <Link to="/en/job-form" className="button button-en">
          <button className="btn-en"> Free Consultation</button>
        </Link>
      </div>
      <section style={{ marginTop: "-3.5px" }}>
        <div className="search-support pl-15 pr-15">
          <p className="search-support_title">Zero Cost, Job Search Support!</p>
          <p className="search-support_text">
            Join us and let our experts assist you in finding your ideal job.
          </p>
        </div>
        <div className="search-header-img">
          {headerImg.map((image, i) => (
            <img src={image.img} alt={"header image " + i} />
          ))}
        </div>
      </section>

      <section className="home-page home-page_en">
        <div className="row">
          <section>
            <h2>Why Select Our Solutions</h2>
            <div className="our-solutions mb-50">
              <img src={ourSolution[0].img} alt="benefits" />
              <div className="our-solutions_paragraph">
                <p className="our-solutions_paragraph_title">
                  Comprehensive Benefits
                </p>
                <p className="our-solutions_paragraph_text">
                  QGM introduces jobs that come with solid benefits like health
                  insurance, pension plans, and commuting allowances, ensuring
                  candidates find positions that offer secure and supportive
                  work environments.
                </p>
              </div>
            </div>
            <div className="our-solutions our-solutions_invert mb-50">
              <div className="our-solutions_paragraph">
                <p className="our-solutions_paragraph_title">
                  Unique Job Opportunities
                </p>
                <p className="our-solutions_paragraph_text">
                  QGM provides exclusive listings not found on typical job
                  sites, especially from top-tier Japanese companies. These
                  positions offer excellent benefits and career advancement
                  opportunities, accessible only through us.
                </p>
              </div>
              <img src={ourSolution[1].img} alt="opportunities mb-50" />
            </div>
            <div className="our-solutions mb-50">
              <img src={ourSolution[2].img} alt="interview" />
              <div className="our-solutions_paragraph">
                <p className="our-solutions_paragraph_title">
                  Interview Preparation Support
                </p>
                <p className="our-solutions_paragraph_text">
                  QGM offers thorough interview preparation, guiding candidates
                  with personalized tips, company-specific questions, and
                  techniques to boost confidence, ensuring better chances of
                  success in interviews.
                </p>
              </div>
            </div>
            <p className="our-solutions_bottom">
              *While we provide recruitment services, we do not offer temporary
              staffing services.
            </p>
          </section>
          <ContactSectionEn />
          <section>
            <h2>How it works</h2>
            <div className="how-works">
              <p className="text">
                From registering with our staffing service to receiving job
                offers and securing a position, we provide personalized support
                every step of the way. For a more detailed explanation of the
                process, please visit <Link to="/en/process">More details</Link>.
              </p>
              <div className="how-works_flow">
                {howWorks.map(content => (
                  <div className="how-works_flow_arrow">
                    <p className="how-works_flow_number">{content.number}</p>
                    <div className="how-works_flow_box">
                      <p>{content.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <div className="pr-30 pl-30" style={{width: "100%"}}>
            <ServiceSectionEn />
          </div>
          <section
            style={{
              backgroundImage: "url(" + makeHappy + ")",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
              backgroundSize: "cover",
              padding: "47px 0",
              position: "relative",
            }}
          >
            <div className="layer"></div>
            <div className="layer2"></div>

            <div className="make-happy max-width-size pt-50 pb-50 pl-30 pr-30">
              <h3>“MAKE EVERYONE HAPPY”</h3>
              <p>
                We will strive to be reliable, trusted recruiting consultants
                for a job seeker. Similarly, for an employer seeking employees
                we will serve to meet your staffing needs as professional
                recruitment specialists.
                <br />
                <br />
                Based in Aguascalientes, QUICK GLOBAL MEXICO, S.A. DE C.V.
                functions as an agency to facilitate job search and career
                transition for individuals who are currently working in Mexico.
                Our experienced, skilled recruiting consultants will give you a
                career advice based on your career goals and needs. Using our
                job search and career change support services, you will discover
                and land a position that is just right for you.
              </p>
            </div>
          </section>
          <section>
            <h2 className="h2-form">Consultation form</h2>
            <div className="consultation-form">
              <p className="text">
                Our company strives to meet the preferences of our registrants
                as much as possible. We can negotiate employment conditions with
                hiring companies on your behalf if needed. Please note that some
                requests may not be accommodated.
              </p>
            </div>
            <JobFormEn />
          </section>
        </div>
      </section>
    </div>
  )
}

export default IndexEnPage
