import { Link } from "gatsby"
import React from "react"

export const ContactSectionEn = () => {
  return (
    <section className="bg-cyan">
      <div className="contact-section-en pt-50 pb-50">
        <p className="title">SEEKING CAREER ADVICE?</p>
        <p className="text">We're here to help anytime. Just reach out to us!</p>
        <Link to="/en/job-form">
          <button>Free Consultation</button>
        </Link>
      </div>
    </section>
  )
}
